var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper-inner"},[_c('div',{staticClass:"inner-content"},[_c('router-link',{staticClass:"back-profile",attrs:{"to":"/profile"}},[_c('img',{attrs:{"src":require("@/assets/img/arrow-round-back.svg"),"alt":""}}),_vm._v(" Назад ")]),_c('div',{staticClass:"wrapper-profile"},[_c('h2',[_vm._v("Личные данные")]),_c('validation-observer',{ref:"observer"},[_c('form',{staticClass:"wrapper-profile-form",on:{"submit":function($event){$event.preventDefault();return _vm.updateUser.apply(null, arguments)}}},[_c('div',{staticClass:"profile-form-flex"},[_c('validation-provider',{attrs:{"name":"Имя и фамилия","rules":"required|fullname","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('label',{attrs:{"for":"name"}},[_vm._v("Имя и фамилия*")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.user.name),expression:"user.name",modifiers:{"trim":true}}],attrs:{"id":"name","type":"text","placeholder":"Евгений Вебер","required":""},domProps:{"value":(_vm.user.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('error-pop-over',{attrs:{"errors":errors,"reset-function":reset}})]}}])}),_c('div',[_c('label',[_vm._v("Телефон*")]),_c('masked-input',{attrs:{"mask":"\\+1 (111) 111-11-11","placeholder":"Введите номер телефона","required":""},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.phone"}})],1)],1),_c('div',{staticClass:"profile-form-flex"},[_c('validation-provider',{attrs:{"name":"E-mail","rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('label',{attrs:{"for":"email"}},[_vm._v("E-mail*")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.user.email),expression:"user.email",modifiers:{"trim":true}}],attrs:{"id":"email","type":"text","placeholder":"survey@themind.ru","required":""},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('error-pop-over',{attrs:{"reset-function":reset,"errors":errors}})]}}])}),_c('validation-provider',{attrs:{"name":"Страна","rules":"required|fullname","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('label',{attrs:{"for":"country"}},[_vm._v("Страна*")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.user.country),expression:"user.country",modifiers:{"trim":true}}],attrs:{"id":"country","type":"text","placeholder":"Россия","required":""},domProps:{"value":(_vm.user.country)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "country", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('error-pop-over',{attrs:{"errors":errors,"reset-function":reset}})]}}])})],1),_c('div',{staticClass:"profile-form-flex"},[_c('validation-provider',{attrs:{"name":"Город","rules":"required|city","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('label',{attrs:{"for":"city"}},[_vm._v("Город*")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.user.city),expression:"user.city",modifiers:{"trim":true}}],attrs:{"id":"city","type":"text","placeholder":"Архангельск","required":""},domProps:{"value":(_vm.user.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "city", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('error-pop-over',{attrs:{"errors":errors,"reset-function":reset}})]}}])}),_c('validation-provider',{attrs:{"name":"Дата рождения","rules":"required|date:@maxStartDate","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('label',[_vm._v("Дата рождения*")]),_c('masked-input',{attrs:{"mask":"11.11.1111","placeholder":"05.02.1993","required":""},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"birthday"}}),_c('error-pop-over',{attrs:{"errors":errors,"reset-function":reset}})]}}])})],1),_c('div',{staticClass:"profile-form-button"},[_c('div',[_c('input',{staticClass:"submit-add",attrs:{"type":"submit","value":"Сохранить"}})]),_c('div',[_c('input',{staticClass:"submit-delete",attrs:{"type":"button","value":"Сменить пароль","disabled":_vm.disabledRecover},on:{"click":_vm.recoverPassword}})])])])])],1),_c('modal-alert',{attrs:{"show-modal":_vm.showAlert,"alert-text":_vm.alertText,"action-modal":_vm.closeAlert,"action-text":"Продолжить"},on:{"update:showModal":function($event){_vm.showAlert=$event},"update:show-modal":function($event){_vm.showAlert=$event}}})],1),_c('div',{staticClass:"clear"})])}
var staticRenderFns = []

export { render, staticRenderFns }