<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <router-link to="/profile" class="back-profile">
        <img src="@/assets/img/arrow-round-back.svg" alt="" /> Назад
      </router-link>
      <div class="wrapper-profile">
        <h2>Личные данные</h2>
        <validation-observer ref="observer">
          <form class="wrapper-profile-form" @submit.prevent="updateUser">
            <div class="profile-form-flex">
              <validation-provider
                name="Имя и фамилия"
                rules="required|fullname"
                tag="div"
                v-slot="{ errors, reset }"
              >
                <label for="name">Имя и фамилия*</label>
                <input
                  id="name"
                  type="text"
                  placeholder="Евгений Вебер"
                  v-model.trim="user.name"
                  required
                />

                <error-pop-over :errors="errors" :reset-function="reset" />
              </validation-provider>
              <div>
                <label>Телефон*</label>
                <masked-input
                  v-model.trim="user.phone"
                  mask="\+1 (111) 111-11-11"
                  placeholder="Введите номер телефона"
                  required
                />
              </div>
            </div>
            <div class="profile-form-flex">
              <validation-provider
                name="E-mail"
                rules="required|email"
                tag="div"
                v-slot="{ errors, reset }"
              >
                <label for="email">E-mail*</label>
                <input
                  id="email"
                  v-model.trim="user.email"
                  type="text"
                  placeholder="survey@themind.ru"
                  required
                />
                <error-pop-over :reset-function="reset" :errors="errors" />
              </validation-provider>
              <validation-provider
                name="Страна"
                rules="required|fullname"
                tag="div"
                v-slot="{ errors, reset }"
              >
                <label for="country">Страна*</label>
                <input
                  id="country"
                  v-model.trim="user.country"
                  type="text"
                  placeholder="Россия"
                  required
                />
                <error-pop-over :errors="errors" :reset-function="reset" />
              </validation-provider>
            </div>
            <div class="profile-form-flex">
              <validation-provider
                name="Город"
                rules="required|city"
                tag="div"
                v-slot="{ errors, reset }"
              >
                <label for="city">Город*</label>
                <input
                  id="city"
                  v-model.trim="user.city"
                  type="text"
                  placeholder="Архангельск"
                  required
                />
                <error-pop-over :errors="errors" :reset-function="reset" />
              </validation-provider>
              <validation-provider
                name="Дата рождения"
                rules="required|date:@maxStartDate"
                tag="div"
                v-slot="{ errors, reset }"
              >
                <label>Дата рождения*</label>
                <masked-input
                  v-model.trim="birthday"
                  mask="11.11.1111"
                  placeholder="05.02.1993"
                  required
                />
                <error-pop-over :errors="errors" :reset-function="reset" />
              </validation-provider>
            </div>

            <div class="profile-form-button">
              <div>
                <input class="submit-add" type="submit" value="Сохранить" />
              </div>
              <div>
                <input
                  class="submit-delete"
                  type="button"
                  @click="recoverPassword"
                  value="Сменить пароль"
                  :disabled="disabledRecover"
                />
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
      <modal-alert
        :show-modal.sync="showAlert"
        :alert-text="alertText"
        :action-modal="closeAlert"
        action-text="Продолжить"
      />
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
import Vue from "vue";
import MaskedInput from "vue-masked-input";
import {
  alpha_spaces,
  required,
  alpha_dash,
  email
} from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/ru.json";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import ErrorPopOver from "@/components/page/items/error/ErrorPopOver";
import ModalAlert from "@/components/page/modals/forms/ModalAlert";

extend("fullname", {
  ...alpha_spaces,
  message: messages["alpha_spaces"]
});

extend("city", {
  ...alpha_dash,
  message: messages["alpha_dash"]
});

extend("required", {
  ...required,
  message: messages["required"]
});

extend("email", {
  ...email,
  message: messages["email"]
});

extend("date", {
  params: ["target"],
  validate(value, { target }) {
    return Vue.moment(value, "DD.MM.YYYY").isBetween(
      Vue.moment("01.01.1900", "DD.MM.YYYY"),
      target
    );
  },
  message: "Введите корректную дату от 01.01.1900 до сегодняшнего дня"
});

export default {
  name: "ProfileData",
  components: {
    ModalAlert,
    MaskedInput,
    ValidationObserver,
    ValidationProvider,
    ErrorPopOver
  },
  data() {
    return {
      formData: this.$auth.user() || {},
      showAlert: false,
      disabledRecover: false,
      alertText: ""
    };
  },
  computed: {
    user: {
      get() {
        return this.formData;
      },
      set(n) {
        this.formData = n;
      }
    },
    birthday: {
      get() {
        return Vue.moment(this.formData.birthday, "YYYY-MM-DD").format(
          "DD.MM.YYYY"
        );
      },
      set(n) {
        this.formData.birthday = Vue.moment(n, "DD.MM.YYYY").format(
          "YYYY-MM-DD"
        );
      }
    }
  },
  methods: {
    updateUser() {
      this.axios.post("/auth/update", this.formData).then(response => {
        const userData = response.data.data;
        this.$auth.user(userData);
        this.alertText = "Данные сохранены!";
        this.showAlert = true;
      });
    },
    maxStartDate() {
      return this.moment()
        .startOf("month")
        .add(1, "months")
        .endOf("month")
        .format("DD.MM.YYYY");
    },
    recoverPassword() {
      this.disabledRecover = true;
      this.$http
        .get("/auth/restore/request", {
          params: { email: this.$auth.user().email }
        })
        .then(response => {
          const data = response.data;
          this.alertText = data
            ? "Инструкции по смене пароля отправлены на Ваш email"
            : "Произошла ошибка";
          this.showAlert = true;
        });
    },
    closeAlert() {
      this.disabledRecover = false;
      this.showAlert = false;
    }
  }
};
</script>

<style scoped></style>
